<template>
  <div class="position-relative">
    <b-row>

      <b-col md="4">

        <div v-if="selectedItems.length === 0">
          <p>Please select any product to Import and Upload on eBay.</p>
        </div>


        <div v-else>
          <b-dropdown variant="primary" text="Actions" class="mr-1">
            <b-dropdown-item>
              <template>
              </template>
              Import
            </b-dropdown-item>
            <b-dropdown-item>
              <template>
              </template>
              Upload on eBay
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </b-col>

      <b-col md="4">
      </b-col>
      <b-col md="4">

        <b-row>

          <b-col md="8">
            <b-form-group label-cols-sm="2" label-align-sm="right" label-size="md" label-for="filterInput">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label-cols-sm="4" label-align-sm="right" label-size="sm" label-for="perPageInput">
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                @change="savePerPage(perPage)" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <span>Total Rows : <b>{{ totalRows }}</b></span>

    <b-table ref="myTable" :items="suppliersWithImageURLs" :fields="fields" responsive="sm" :sticky-header="false" striped
      small id="supplier-table" :per-page="perPage" :current-page="currentPage" :filter="filter"
      :filter-included-fields="filterOn" @filtered="onFiltered">

      <template #cell(select)="row">
        <b-form-checkbox v-model="row.item.checkboxStatus" v-on:change="selected(row.item.id)"
          class="custom-control-primary">
          <feather-icon v-b-tooltip.hover title="Label has been generated" v-if="row.item.generated == 1"
            icon="TagIcon" />
        </b-form-checkbox>
        <small>{{ row.item.label_creation_dt }}</small>
      </template>

      <template #cell(photo)="row">
        <div v-lazy-container="{
          selector: 'img',
          error:
            'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
          loading:
            'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
        }">
          <img v-if="row.item.photo" v-for="photo in getImages(row.item.photo)" :src="photo" alt="Product Photo"
            style="width: 100px; height: 100px;" />
        </div>

        <span class="cursor-pointer" @click="row.toggleDetails">
          <feather-icon v-if="!row.detailsShowing" v-model="row.detailsShowing" icon="PlusIcon" />
          <feather-icon v-else icon="MinusIcon" v-model="row.detailsShowing" />
          <span>{{ row.detailsShowing ? 'Hide' : 'Show Details' }}</span>
        </span>
      </template>


      <template #cell(tags)="row">
    <div>
      <span v-for="tag in row.item.tags" :key="tag.id" class="tag">
        {{ tag.tags }}
        <feather-icon
          icon="XIcon"
          class="remove-tag"
          @click="deleteTag(tag.id)"
        />
      </span>
    </div>
    <feather-icon icon="TagIcon" class="add-tag-icon" @click="showInputForm(row.item.id)" />
          <b-form-input v-model="newTag" placeholder="Add a tag" @input="updateTagInput(row.item.id, $event)"
            @keyup.enter="addTag(row.item.id, newTag); newTag = ''" v-show="inputFormVisible === row.item.id" />
          <!-- <b-button size="sm" variant="outline-secondary" @click="hideInputForm(row.item.id)"
            v-show="inputFormVisible === row.item.id">
            Cancel
          </b-button>
          <b-button size="sm" variant="primary"
            @click="addTag(row.item.id, newTag); newTag = ''; hideInputForm(row.item.id)"
            v-show="inputFormVisible === row.item.id">
            Submit
          </b-button> -->
        <b-modal v-model="showTagInputModal" title="Add Tag">
          <b-form-group label="New Tag">
            <b-form-input v-model="newTag" />
          </b-form-group>
          <b-button @click="addTag(selectedprdct_id, newTag); closeTagInputModal()">Add Tag</b-button>
        </b-modal>
  </template>

      <template #cell(description)="row">
        <span>{{ row.item.description }}</span>
      </template>
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">

            <b-col md="4" class="mb-1" v-for="item in formattedItems(row.item.items)" :key="item.id">

              <strong>Name: </strong>{{ item.name }}, <strong>Value: </strong>{{ item.value }}
            </b-col>

            <b-col md="4" class="mb-1">
              <strong>Category : </strong>{{ row.item.category }}
            </b-col>


            <b-col md="4" class="mb-1">
              <strong>Dimensions : </strong>{{ row.item.lenght }}L,{{ row.item.width }}W,{{ row.item.height }}H
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Weight : </strong>{{ row.item.kg }}.{{ row.item.grams }}g
            </b-col>

            <b-col md="4" class="mb-1">
              <strong>Supplier : </strong>{{ row.item.supplier }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Description : </strong>

              <!-- {{ row.item.description }} -->

              <span v-html="row.item.description"></span>

            </b-col>
          </b-row>

          <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
            Hide Details
          </b-button>
        </b-card>
      </template>

      <template #cell(action)="row">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
          </template>

          <li role="presentation">
            <router-link :to="`/listings/${row.item.id}`" class="dropdown-item">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </router-link>
          </li>

          <b-dropdown-item @click="deleteprdct(row.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>

    <b-pagination :total-rows="totalRows" v-model="currentPage" :per-page.sync="perPage" first-number last-number
      class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">

      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>

      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
    <b-overlay :show="loader" no-wrap spinner-variant="primary"></b-overlay>
  </div>
</template>
<script>
import {
  BTable,
  BButton,
  VBToggle,
  BOverlay,
  BRow,
  BCol,
  BCard,
  BPagination,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdownDivider,
  BDropdown,
  BDropdownItem,
  BBadge,
  VBTooltip
} from "bootstrap-vue";
import axios from "axios";
import Swal from 'sweetalert2'
import moment from "moment";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import User from '../../Helpers/User'

export default {
  components: {
    BTable,
    BButton,
    Swal,
    BRow,
    BCard,
    BCol,
    BPagination,
    BOverlay,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    ToastificationContent,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: "25",
      pageOptions: [25, 50, 100, 200],
      currentPage: 1,
      totalRows: 1,
      fields: ['select', { key: 'title', label: 'Title', tdClass: 'actionClass', width: '150px' }, 'photo', { key: 'tags', label: 'Tags', tdClass: 'tagsColumnWidth' }, 'tag', 'sku', 'price', { key: "action", label: "Action", tdClass: 'actionClass' }],
      suppliers: [],
      items: '[]',
      filter: null,
      tags: {},
      inputFormVisible: null,
      showTagInputModal: false,
      tagModal: false,
      newTag: '',
      selectedprdct_id: null,
      selectedItems: [],
      products_count: 0,
      filterOn: [],
      loader: false,
      supplierData: {},
      editexpense: {},
    };
  },
  mounted() {
  },
  created() {
    this.getItems();
  },

  watch: {
    currentPage(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.scrollToTop();
      }
    }
  },


  computed: {
    suppliersWithImageURLs() {
      return this.suppliers.map(supplier => {
        if (supplier.photo) {
          const imageType = 'image/jpeg';
          const base64Image = supplier.photo;
          const imageURL = `data:${imageType};base64,${base64Image}`;
          return { ...supplier, imageURL };
        }
        return supplier;
      });
    },
    parsedItems() {
      return JSON.parse(this.items);
    },

    formattedItems() {
      return (items) => {
        if (!items) {
          return [];
        }
        try {
          const parsedItems = JSON.parse(items);
          return parsedItems;
        } catch (error) {
          return [];
        }
      }
    },

  },

  methods: {

    showInputForm(prdct_id) {
      this.showTagInputModal = true;
      this.selectedprdct_id = prdct_id;
    },
    closeTagInputModal() {
      this.showTagInputModal = false;
      this.selectedprdct_id = null;
      this.newTag = '';
    },

    //   showInputForm(prdct_id) {
    //   this.inputFormVisible = prdct_id;
    // },

    hideInputForm(prdct_id) {
      this.inputFormVisible = null;
    },
    //   addTag(prdct_id, tag) {
    //   if (!this.tags[prdct_id]) {
    //     this.$set(this.tags, prdct_id, []);
    //   }
    //   this.tags[prdct_id].push(tag);
    // },

    openTagModal(item) {
      this.tagModal = true;
      this.selectedItem = item;
      this.newTag = '';
    },



    addTag(prdct_id, tag) {
      if (!this.tags[prdct_id]) {
        this.$set(this.tags, prdct_id, []);
      }
      this.tags[prdct_id].push(tag);

      axios.post(process.env.VUE_APP_API + "/tags", {
        prdct_id: prdct_id,
        tags: tag,
      })
        .then((response) => {
          this.getItems();
          console.log('Tag added successfully:', response.data);
        })
        .catch((error) => {
          console.error('Error adding tag:', error);
        });
    }
    ,




    //   addTag(prdct_id, tag) {
    //   if (!this.tags[prdct_id]) {
    //     this.$set(this.tags, prdct_id, []);
    //   }
    //   this.tags[prdct_id].push(tag);

    //   if (this.selectedItem && this.selectedItem.id) {
    //   axios
    //     .post(process.env.VUE_APP_API + 'http://localhost:8000/api/tags', {
    //       prdct_id: this.selectedItem.id,
    //       tag: tag,
    //     })
    //     .then((response) => {
    //       if (response.data.success) {
    //       } else {
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // } else {
    // }

    // },

    formatTags(tags) {
      // The method to format tags as a comma-separated string
      return tags.map(tag => tag.tags).join(', ');
    },

    deleteTag(tagId) {
  axios.delete(`${process.env.VUE_APP_API}/tags/${tagId}`)
    .then((response) => {
      this.getItems();
      if (response.data.success) {
        this.removeTagFromUI(tagId);
      } else {
        console.error('Failed to delete tag');
      }
    })
    .catch((error) => {
      console.error('Error deleting tag:', error);
    });
},


    removeTag(prdct_id, tag) {
      const index = this.tags[prdct_id].indexOf(tag);
      if (index !== -1) {
        this.tags[prdct_id].splice(index, 1);
      }
    },
    getImages(allImage) {
      if (allImage) {
        return allImage.split(", ");
      } else {
        return [];
      }
    },

    scrollToTop() {
      const tableElement = this.$refs.myTable.$el;
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
    },

    selected(id) {
      var status = false

      for (var i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i] == id) {
          status = true
        }
      }
      if (status) {
        const index = this.selectedItems.indexOf(id);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
      else {
        this.selectedItems.push(id)
      }
    },

    sendProductEditProps(param) {
      console.log('param', param)
      this.editproduct = param
    },

    checkPermission(permission) {
      return User.checkPermission(permission)
    },
    sendprdctEditProps(param) {
      this.editprdct = param
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getItems() {
      this.loader = true;
      axios
        .get(process.env.VUE_APP_API + "/prdct")
        .then((res) => {
          this.loader = false;
          this.suppliers = res["data"];
          this.totalRows = this.suppliers.length;
          this.products_count = res["data"].length;
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },


    deleteprdct(prdctId) {
      if (prdctId) {
        Swal.fire({
          title: 'Are you sure to delete this Product?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;
            axios({
              method: 'delete',
              url: process.env.VUE_APP_API + '/prdct/prdct',
              data: {
                'prdctId': prdctId,
              }
            })
              .then((res) => {
                if (res.data.success) {
                  this.getItems();
                  this.loader = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Product Deleted',
                      icon: 'AlertOctagonIcon',
                      text: '',
                      variant: 'danger',
                    },
                  },
                    {
                      position: 'top-right'
                    });
                } else {
                  this.loader = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Failed',
                      icon: 'AlertOctagonIcon',
                      text: '',
                      variant: 'danger',
                    },
                  },
                    {
                      position: 'top-left'
                    });
                }
              })
              .catch((error) => {
                this.loader = false;
                console.log(error);
              });
          }
        });
      }
    }


  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("hh:mm:ss A");
      }
    },
  },
}

</script>
    
<style>
.actionClass {
  max-width: 300px;
}

.tagsColumnWidth {
  width: 200px;
}

.tag {
  display: inline-block;
  background-color: #7A1616;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
}

.remove-tag {
  cursor: pointer;
}

.add-tag-icon {
  cursor: pointer;
  color: #000000;
}
</style>